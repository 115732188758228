<template>
  <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    dataPoints: {
      type: Array,
    },
    dataLabels: {
      type: Array,
    },
    showLabels: {
      type: Boolean,
    },
    titleText: {
      type: String,
    },
    paddingLeft: {
      type: Number,
      default: 10
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 215
    },
    height: {
      type: Number,
      default: 365
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Array,
    //   default: () => [{beforeInit(chart, ctx) {
    //       const originalFit = chart.legend.fit;
    //       chart.legend.fit = function fit() {
    //         originalFit.bind(chart.legend)();
    //         // this.height += 20;
    //       }
    //     }}
    //   ]
    // }
  },
  data() {
    return {
      chartData: {
        labels: this.dataLabels,
        datasets: [
          {
            label: ['2020'],
            backgroundColor: ['#FFD601'],
            data: this.dataPoints[0]
          },
          {
            label: ['2021'],
            backgroundColor: ['#002156'],
            data: this.dataPoints[1]
          },
          {
            label: ['2022'],
            backgroundColor: ['#2331D0'],
            data: this.dataPoints[2]
          },
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y:{
            display: false,
            min: 0, // minimum value
            max: 100 // maximum value
          },
          x:{
            grid:{
              display: false,
            }
          }
        },
        layout: {
          padding: {
            left: this.paddingLeft,
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          title: {
            display: true,
            position: 'bottom',
            align: "center",
            font: {weight: 500},
            text: this.titleText,
          },
          datalabels: {
            align: 'end',
            anchor: 'end',
            color: 'black',
            font: {
              size: 11,
            },
            formatter: (value, ctx) => {
              return value += "%"
            },
            borderColor: this.bgColour,
            borderRadius: 25,
            borderWidth: 2,
            display: 'true'
          },
          legend: {
            display: this.showLabels,
            align: 'start',
            labels: {
              boxWidth: 15,
              boxHeight: 15,
              padding: 15,
            }
          }
        },
      },
    }
  }
}

</script>

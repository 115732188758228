<template>
  <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
  />
</template>

<script>
import {Doughnut} from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {ArcElement, CategoryScale, Chart as ChartJS, Legend, Title, Tooltip} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels)

let labels = ['1-12', '13-26', '27-52', 'No Max'];
let colors = ['#FFD601', '#2331D0', '#FB726B', '#002156'];

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut
  },
  props: {
    dataPoints: {type: Array},
    bgColour: String,
    rotation: {
      type: Number,
      default: 180
    },
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 310
    },
    height: {
      type: Number,
      default: 335
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => [{beforeInit(chart, ctx) {
          const originalFit = chart.legend.fit;
          chart.legend.fit = function fit() {
            originalFit.bind(chart.legend)();
            this.height += 25;
          }
        }}
      ]
    }
  },
  data() {
    return {
      chartData: {
        labels: labels.slice(0, this.dataPoints.length),
        datasets: [
          {
            backgroundColor: ['#FFD601', '#2331D0', '#FB726B', '#002156'],
            data: this.dataPoints,
            borderWidth: 0,
            rotation: this.rotation,
            // padding: 10,
            datalabels: {
              anchor: 'end'
            },
          }
        ]
      },
      chartOptions: {
        layout: {
          padding: {
            bottom: 50
          }
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map(data => {
                sum += data;
              });
              if (value !== 0){
                return (value * 100 / sum).toFixed(0) + "%";
              }else {
                return null
              }
            },
            backgroundColor: function(context) {
              return context.dataset.backgroundColor;
            },
            borderColor: this.bgColour,
            borderRadius: 25,
            borderWidth: 2,
            color: 'white',
            align: "end",
            display: 'true'
          },
          legend: {
            display: true,
            labels: {
              boxWidth: 15,
              boxHeight: 15,
              padding: 15,
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>

<template>

  <div class="benchmarking">
    <div class="error">
      <h1>Heads up!</h1>
      <p>The data on this page is not optimized for Mobile viewing,
        please access this page on a desktop for the best experience
        or click the link at the bottom of the page to download a PDF version.</p>
    </div>
    <div class="content">
      <div class="header">
        <div class="header-left">
          <h1 style="margin-bottom: 0; font-size: 50px;">2023</h1>
        </div>
        <div class="header-right">
          <h1>The Larkin Company</h1>
          <h2>Benchmarking Results</h2>
        </div>

      </div>
      <div class="section body-text">
        <p>Each year, Larkin reviews our clients’ leave of absence (LOA) policies to identify trends
          and changes over time. This year’s report features over 150 companies across a variety
          of industries, with the largest representation coming from high technology.</p>
        <p>
          The first area of focus, "Leaves", explores the maximum time duration granted for each
          leave type. The second, "Salary Continuation", outlines the duration and amount of LOA
          pay provided. In this context, LOA pay is defined as company pay in addition to statutory
          or short-term disability benefits. LOA pay may also refer to tax-free disability plans paying
          70% salary or greater. The purpose is to provide employees with whole or mostly whole
          pay for a certain duration during a leave of absence.</p>
      </div>
      <div class="section" style="background-color: #ECE7E0">
        <div class="section-title">
          <img src="/img/ee/pregnancy-leave.svg" class="icon svg-icon">
          <h1>Pregnancy</h1>
        </div>
        <div class="subsection">
          <div class="sub-left">
            <h2 style="background-color: #F7F5F3">Leave Duration*</h2>
            <DoughnutChart :dataPoints=[50,41,3,6] bgColour="#ECE7E0" :order="[1, 0, 3, 2]"/>
            <div class="chart-label-text">Weeks</div>
          </div>
          <div class="sub-right">
            <h2 style="background-color: #F7F5F3" >Salary Continuation**</h2>
            <div class="bar-graphs">
              <p class="axis-label">% Of Larkin's Clients</p>
              <BarChart :dataPoints=[[27],[31],[27]] :dataLabels="['2020         2021         2022']"
                        :showLabels="true" :titleText="'Traditional disability benefits***'" :width="215"/>
              <BarChart :dataPoints=[[2,41,30],[3,37,29],[5,41,27]] :paddingLeft="30"
                        :dataLabels="['Up to 6 weeks', '6-12 weeks', '13-26 weeks']"
                        :showLabels="false" :titleText="'70% pay or greater'" :width="350" />
            </div>

          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-title">
          <img src="/img/ee/parental.svg" class="icon svg-icon">
          <h1>Parental</h1>
        </div>
        <div class="subsection">
          <div class="sub-left">
            <h2 style="background-color: #ECE7E0">Leave Duration*</h2>
            <DoughnutChart :dataPoints=[66,34] bgColour="#F7F5F3" rotation="331"/>
            <div class="chart-label-text">Weeks</div>
          </div>
          <div class="sub-right">
            <h2 style="background-color: #ECE7E0" >Salary Continuation**</h2>
            <div class="bar-graphs">
              <p class="axis-label">% Of Larkin's Clients</p>
              <BarChart :dataPoints=[[20],[28],[16]] :dataLabels="['2020         2021         2022']"
                        :showLabels="true" :titleText="'No LOA pay'" :width="215"/>
              <BarChart :dataPoints=[[6,46,28],[7,42,23],[6,45,33]] :paddingLeft="30"
                        :dataLabels="['Up to 6 weeks', '6-12 weeks', '13-26 weeks']"
                        :showLabels="false" :titleText="'70% pay or greater'" :width="350" />
            </div>
          </div>
        </div>
      </div>
      <div class="section" style="background-color: #ECE7E0">
        <div class="section-title">
          <img src="/img/ee/family-care.svg" class="icon svg-icon">
          <h1>Family Care</h1>
        </div>
        <div class="subsection">
          <div class="sub-left">
            <h2 style="background-color: #F7F5F3">Leave Duration*</h2>
            <DoughnutChart :dataPoints=[96,4] bgColour="#ECE7E0" rotation="277"/>
            <div class="chart-label-text">Weeks</div>
          </div>
          <div class="sub-right">
            <h2 style="background-color: #F7F5F3" >Salary Continuation**</h2>
            <div class="bar-graphs">
              <p class="axis-label">% Of Larkin's Clients</p>
              <BarChart :dataPoints=[[56],[61],[61]] :dataLabels="['2020         2021         2022']"
                        :showLabels="true" :titleText="'No LOA pay'" :width="215"/>
              <BarChart :dataPoints=[[8,34,2],[9,28,2],[9,28,2]] :paddingLeft="30"
                        :dataLabels="['Up to 6 weeks', '6-12 weeks', '13-26 weeks']"
                        :showLabels="false" :titleText="'70% pay or greater'" :width="350" />
            </div>
          </div>
        </div>
      </div>
      <div class="section" >
        <div class="section-title">
          <img src="/img/ee/medical.svg" class="icon svg-icon">
          <h1>Medical</h1>
        </div>
        <div class="subsection">
          <div class="sub-left">
            <h2 style="background-color: #ECE7E0">Leave Duration*</h2>
            <DoughnutChart :dataPoints=[71,21,4,4] bgColour="#F7F5F3" />
            <div class="chart-label-text">Weeks</div>
          </div>
          <div class="sub-right">
            <h2 style="background-color: #ECE7E0" >Salary Continuation**</h2>
            <div class="bar-graphs">
              <p class="axis-label">% Of Larkin's Clients</p>
              <BarChart :dataPoints=[[39],[45],[47]] :dataLabels="['2020         2021         2022']"
                        :showLabels="true" :titleText="'Traditional disability benefits***'" :width="215"/>
              <BarChart :dataPoints=[[5,40,16],[5,39,11],[14,33,6]] :paddingLeft="40"
                        :dataLabels="['Up to 6 weeks', '6-12 weeks', '13-26 weeks']"
                        :showLabels="false" :titleText="'70% pay or greater'" :width="350" />
            </div>
          </div>
        </div>
      </div>
      <div class="footer" style="background-color: #FFD601;">
        <i>*Maximum leave durations granted for employees eligible under state and/or federal laws.</i>
        <br/>
        <i>**Benefits may include taxed and tax-free benefits.</i>
        <br/>
        <i>***Traditional Disability Benefits may include plan designs covering up to 66.67% of pay.</i>
      </div>
      <div class="footer" style="display: flex; flex-direction: row; justify-content: space-between">
        <img class="colorize-logo" src="/img/logo/larkin-logo.svg" style="fill: black">
        <div style="font-size: 10px; margin: 0">
          <p>PO Box 910<br> Roseville, CA 95661</p>
          <p>P: 650.938.0933 / F: 650.938.0943<br>thelarkincompany.com</p>
        </div>

      </div>
    </div>
    <a class="download-button" href="/img/PDFs/Larkin_BenchmarkingPDF_2023_090623.pdf" download>Download PDF Version<i class="icon icon-download"></i>
    </a>

  </div>
</template>

<style lang="scss">
.error {
  display: none;
  margin: 20% 0;
  text-align: center;
  border: 3px solid #FFD601;
  padding: 20px;
  border-radius: 20px;
}
.benchmarking{
  font-family: "Avenir Medium", "sans-serif";
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #F7F5F3;
  .body-text {
    /*font-size: 12px;*/
    font-weight: lighter;
    letter-spacing: normal;
  }
  .section{
    max-width: 1100px;
    padding: 3% 7%;
  }
  .section-title {
    display: flex;
    align-items: center;
    padding: 0 0 15px 15px;
  }
  .section-title .icon {
    font-size: 60px;
    padding-right: 10px;
  }
  .section-title h1 {
    font-size: 40px;
    margin: 0;
  }
  .subsection{
    display: flex;
    flex-direction: row;
    height: 440px;
  }
  .section h2, h1{
    font-weight: bold;
    padding: 7px 10px;
  }
  .sub-left{
    width: 35%;
    margin-right: 1%;
  }
  .sub-right{
    width: 65%;
  }
  .axis-label{
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    font-size: 14px;
    margin: 0;
    padding-top: 45px;
    width: 5px;
    display: flex;
  }
  .svg-icon {
    width: 75px;
  }
  .header{
    display: flex;
    flex-direction: row;
    height: 20%;
    width: 100%;
    margin: 2% 0 2% 0;
    align-items: center;
    justify-content: left;
  }
  .header h1{
    font-size: 54px;
    font-weight: bold;
    padding-left: 0;
  }
  .header h2{
    font-size: 38px;
    font-weight: normal;
    margin-bottom: 0;
  }
  .header-left{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 45px 15px;
    width: 20%;
    background-color: #FFD601;
  }
  .header-right{
    display: flex;
    flex-direction: column;
    padding-left: 40px;
  }
  .chart-label-text {
    position: relative;
    display: flex;
    justify-content: center;
    top: -40%
  }
  .bar-graphs{
    display: flex;
    flex-direction: row;
  }
  .download-button{
    display: flex;
    color: #25251f;
    font-size: 17px;
    align-items: center;
    justify-content: space-between;
    width: 275px;
    background-color: #FFD601;
    padding: 15px 25px;
    margin: 20px;
  }
  .download-button:hover{
    background-color: #f5d100;
    color: black;
  }
  .footer, footer {
    font-size: 12px;
    width: 100%;
    padding: 20px;
    margin-top: 40px;
  }
  .colorize-logo {
    filter: brightness(0.2) sepia(1);
  }
  @media only screen and (min-width: 481px) and (max-width: 1050px) {
    .error{ display: block}
    .header-left h1{
      font-size: 20px !important;
    }
    .section{
      max-width: 481px;
      padding: 3% 7%;
    }
    .subsection{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
    .sub-left {
      width: 100%;
    }
    .sub-right{
      width: 100%;
    }
    .bar-graphs{
      flex-direction: column;
    }
    .axis-label {
      display: none;
    }
  }
  @media only screen and (max-width: 480px) {
    .content {
      display: none;
    }
    .error {
      font-size: 18px;
      display: block;
    }
  }
}
</style>

<script>
import ApiProperties from '../../../src/main/resources/application'
import get from "just-safe-get";
import DoughnutChart from "@/components/charts/Doughnut";
import BarChart from "@/components/charts/Bar"


export default {
  name: 'benchmarking',
  components: {DoughnutChart, BarChart},
  data: function() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: false
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        },
      ],
      errors: [],
      dashboardUrl: {}
    }
  },
  created() {
    this.$http.get(ApiProperties.api.reportConfig).then(response => {
      this.dashboardUrl = response.data.dashboardUrl;
    });
  }
}
</script>
